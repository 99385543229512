import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { autobind } from "react-decoration";
import gql from "graphql-tag";
import client from "lib/ApolloClient";
import { page } from "components/page";
import { navigate } from "gatsby";
import qs from "query-string";
import axios from "axios";

axios.defaults.headers.common = {
    "Cache-Control": "no-cache",
    "Pragma": "no-cache",
    "Expires": "0"
};
  

const setFcuOauthLogin = gql`
    mutation setFcuOauthLogin($form: FcuOauthForm) {
        result: setFcuOauthLogin(Input: $form) {
            success
            errorType
            message
        }
    }
`;

@withI18next(["common"])
@page
class Oauth extends React.Component {
    constructor(props) {
        super(props);
        let alertHiddenString = props.appStore.globalConfig["jumperrwd.login.alertHidden"];
        let alertHidden = alertHiddenString === "1";
        this.state = {
            auth: props.readerStore.auth,
            alertHidden: alertHidden,
        };
    }

    async componentDidMount() {
        await this.sendOauth(this.props);
    }

    async componentWillReceiveProps(props) {
        if (this.state.auth !== props.readerStore.auth) {
            this.setState({ auth: props.readerStore.auth });
            await this.sendOauth(props);
        }
    }

    @autobind
    async sendOauth(props) {
        let { search, appStore } = props;
        let { globalConfig } = appStore;
        let params = { ...search };
        let { user_code = "", login = "", url = "" } = params;
        let fcuClientID = globalConfig["fcu.clientid"] || "";
        let toLogin = login === "true";
        let base_href = globalConfig["jumperrwd.common.base_href"] || "";
        let url2 = this.props.readerStore.session.OLOGINRURL || "";
        if (toLogin) {
            if (url !== "") {
                let setSessionParams = {
                    key: "OLOGINRURL",
                    value: url
                };
                await axios.get(["/api/jumperrwdWs/setSession", qs.stringify(setSessionParams)].join("?")).then((res) => {});
            }
            if (fcuClientID !== "") {
                //逢甲特殊規格
                let params = {
                    client_id: fcuClientID,
                    client_url: base_href + "/api/jumperrwdWs/oauth"
                }
                let url = ["https://opendata.fcu.edu.tw/fcuOauth/Auth.aspx", qs.stringify(params)].join("?")
                window.location.href = url;
            }
        } else {
            if (url2 !== "") {
                await axios.get(`/api/jumperrwdWs/setSession?key=OLOGINRURL&value=`).then((res) => {});
            }
        }
        if (user_code !== "") {
            client.jumperrwdClient
            .mutate({
                mutation: setFcuOauthLogin,
                variables: {
                    form: {
                        userCode: user_code,
                    },
                },
            })
            .then((res) => {
                let { result } = res.data;
                if (result.success) {
                        if (!this.state.alertHidden) {
                            alert(this.props.t("jumperrwd.login.loginSuccess"));
                        }
                        if (url2 !== "") {
                            window.location.href = url2;
                        } else {
                            navigate("/");
                        }
                } else {
                    alert(this.props.t("jumperrwd.login.loginFailed"));
                    navigate("/");
                }
            });
        }
    }

    @autobind
    successTo(url2) {
        if (url2 !== "") {
            window.location.href = url2;
        } else {
            navigate("/");
        }
    }

    render() {
        let { t, isprivate, i18n } = this.props;
        return (
            <Layout t={t} i18n={i18n} isprivate={isprivate} hiddenBg={true}>
                <div
                    ref={(c) => {
                        this.container = c;
                    }}
                />
            </Layout>
        );
    }
}

export default Oauth;
